import React  from "react"; 
import { Row, Col, Container } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Img from 'gatsby-image';
import parse from 'html-react-parser';
import VirtualTourPlay from "./Play/CustomVideo"

// import "./Home/GetKnow/GetKnow.scss";

const StaffStory = (props) => {


  return (
    <React.Fragment>

      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <div className="other-areas-block testimonial-customer-stories components--CustomerStory-CustomerStory">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="block-heading">

                  {props.Title &&
                    <span class="sub-title">{props.Title}</span>
                  }
                  {props.Description &&
                    parse(props.Description)
                  }
                </div>
                <div className="other-areas-list">
                  <Row>
                    {props.Add_Staff_Stories && props.Add_Staff_Stories.map((item, index) => (

                      <Col lg={4}>
                        <div className="other-areas-item img-zoom">
                          <div className="other-areas-img">
                            {item.Image &&
                              <Img fluid={item.Image.url_sharp.childImageSharp.fluid} alt={item.Image.alternativeText} />
                            }
                            <VirtualTourPlay url={item.Video_URL} />

                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>


                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ScrollAnimation>
    </React.Fragment>
  );
};

export default StaffStory;
