import React from 'react';
import { useEffect } from 'react';
import { graphql } from 'gatsby'
import { useMatch } from "@reach/router"
import Modules from "../components/modules"
import NewsDetailsPage from "../components/News/NewsDetails"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";


import { LATEST_NEWS_PAGE_URL } from "../components/common/site/constants";


const DefaultTemplate = (props) => {

  const GQLPage = props.data.glstrapi?.article;
  const Page_Modules = props.data.glstrapi?.article?.Modules;

  const NewsDetails = useMatch(LATEST_NEWS_PAGE_URL.alias + "/:slug")
  
  useEffect(() => {
    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  },[])

  let custom_css = ''
  let additional_class = ''
  if (GQLPage.Custom_CSS_Class) {
    custom_css = GQLPage.Custom_CSS_Class
  }

  if (GQLPage.Layout === 'Staticpage') {
    additional_class = 'static-page-detail'
  }
  if (NewsDetails !== null) {
    var URL = NewsDetails.slug
    // var MAIN_URL = NewsDetails.item
    return (
      <NewsDetailsPage slug={URL} />
    )
  }

  return (
    <div className={`${custom_css} ${additional_class}`}>
      <Modules page={GQLPage} modules={Page_Modules} {...props} />
    </div>
  )
}
export default DefaultTemplate;

export const pageQuery = graphql`
  query defaultQuery($id: ID!) {
      glstrapi {
        article(id: $id, publicationState: LIVE) {
            Banner_CTA_1_Label
            Banner_CTA_1_Label_Heading
            Banner_CTA_2_Label
            Banner_CTA_2_Label_Heading
            Banner_Content
            Custom_CSS_Class
            Layout
            Meta_Description
            Meta_Title
            Pagename
            Show_Brands_Logo
            Show_Reviews
            id
            Choose_Menu {
              id
              URL
            }
            Banner_CTA_1_Link {
              id
            }
            Banner_CTA_2_Link {
              id
            }
            Banner_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 1800
                    quality: 90
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  ) 
                }
              } 
            }
            Modules {
              ... on GLSTRAPI_ComponentModulesIntro {
                id
                Intro_CTA_Label
                Intro_Content
                Intro_Title
                Intro_Video_URL
                Intro_Image {
                  alternativeText
                  url
                  url_sharp {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 700, maxHeight: 550) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  } 
                }
                Intro_CTA_Link {
                  id
                }
              }  
              ... on GLSTRAPI_ComponentModulesIntroWithoutImage {
                id
                Intro_CTA_Label
                Intro_Content
                Intro_Heading
                Intro_Title
                Intro_CTA_Link {
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesShowHelpModule {
                id
                Select_Module {
                  CTA_1_Label
                  CTA_2_Label
                  Content
                  Name
                  Show_Chart 
                  Help_CTA_1_Link {
                    id
                  }
                  CTA_1_Link {
                    id
                  }
                  CTA_2_Link {
                    id
                  }
                  Select_Form {
                    Select
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesServicesContentBlock {
                Add_New_Block {
                  Title
                  Content
                  CTA_Label
                  CTA_Link {
                    id
                  }
                  Image {
                    alternativeText
                    url
                    url_sharp {
                      childImageSharp {
                        fluid(quality: 90, maxWidth: 550) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesStatistics {
                Statistics_Title 
                About_Statistics
                Add_Stats {
                  Count
                  Details
                } 
                Statistics_Left_CTA_Label
                Statistics_Left_Content
                Statistics_Left_Title
                Statistics_Left_CTA_Link {
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesServicesTiles {
                Services_Background_Title
                Add_Service_Tile {
                  Title
                  Tile_Background_Image {
                    url
                    alternativeText
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO,WEBP]
                          width: 350
                          height: 320
                          quality: 90
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER, fit: COVER }
                        )
                      }
                    } 
                  }
                  Link {
                    id
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesAccreditation {
                Add_Accreditation {
                  Title
                  URL
                  Content
                  Image {
                    url
                    alternativeText
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesStaffStories {
                id
                Title
                Description
                Add_Staff_Stories {
                  Video_URL
                  id
                  Image {
                    alternativeText
                    url
                    url_sharp {
                      childImageSharp {
                        fluid(quality: 90, maxWidth: 440) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesGlobalModules {
                Choose_Module
              }
              ... on GLSTRAPI_ComponentModulesCaseStudiesSection {
                id
                Case_Studies_Heading
                Case_Studies_Title
                Select_Case_Studies_to_Show(sort: "Date:desc") {
                  Address
                  Title
                  URL
                  Tile_Image {
                    alternativeText
                    url
                  }
                  imagetransforms
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesFaqSSection {
                FAQ_Title
                Select_FAQs {
                  Answer
                  Question
                }
              }
              ... on GLSTRAPI_ComponentModulesStaticContent {
                Add_Static_Content
                Style
                Show_Sidebar
                Sidebar_Custom_CTA_Label
                Sidebar_Custom_CTA_Link {
                  id
                  URL
                }
              }
              ... on GLSTRAPI_ComponentModulesShowFeedStatistics {
                Stats_Title
              }
              ... on GLSTRAPI_ComponentModulesAccordion {
                id
                Accordion_Intro_Content
                Add_Accordion {
                  Accordion_Content
                  Accordion_Unique_ID
                  Accordion_Title
                }
              }
              ... on GLSTRAPI_ComponentModulesContactPage {
                Add_Contact_Detail {
                  Details
                }
              }
              ... on GLSTRAPI_ComponentModulesCollections {
                Select_Collection
              }
              ... on GLSTRAPI_ComponentModulesBenefitsPointsWithIcon {
                Benefits_Intro
                Benefits_Disclaimer
                Add_Benefit {
                  Content
                  Icon
                  or_Icon_Image {
                    url
                    alternativeText
                  }
                  CTA_Label
                  CTA_Link {
                    id
                  }
                }
              }
              ... on GLSTRAPI_ComponentComponentsAddOurNetwork {
                type: __typename
                CTA_Label
                CTA_Link {
                  URL
                  id
                }
                Content
                Title
              }
              ... on GLSTRAPI_ComponentModulesPeopleStories {
                id
                type: __typename
                Title
                Content 
                Add_People {
                  People {
                    Designation
                    Name
                    Phone
                    Image {
                      alternativeText
                      url
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesBrandsGrid {
                Brands_Main_Heading
                Brands_Intro
                Add_Brand {
                  CTA_Label
                  Content
                  Title
                  CTA_Link {
                    id
                  }
                  Image {
                    alternativeText
                    url
                  }
                }
              }
              ... on GLSTRAPI_ComponentComponentsDisclaimerText {
                Add_Disclaimer_Content
              }
            } 
          }
      }
  }
`