import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/contactselect'
import MultiSelectField from './elements/multi-select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from 'jquery/dist/jquery.min.js'
// import UserAgent from 'user-agents';
import { getIpAddress } from "../common/site/functions";
import { getAllCookie } from "../common/site/cookie";

// import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string" 

function ContactForm(props) {
  // const userAgent = new UserAgent();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [selectedInterst, setSelectIntrest] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [isValid, setIsValid] = useState(true);
  // const [serverResponse, setServerResponse] = React.useState(``)

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const fields = ([
    {
      element: "config",
      formname: "Quick Contact",
      error_text: "Highlighted fields are required | invalid",
      success_text: "Thank you for your message. A member of our team will be in touch soon.",
      email_temp_user: "contact_user",
      email_temp_admin: "contact_admin",
      email_subject_user: "Thanks for your Message",
      email_subject_admin: "Quick Contact",
      email_server_func: "Quick Contact",
      event_tracking: "Quick Contact",
      page_url: "/contact"
    },
    {
      grpmd: "12",
      label: "Full Name*",
      placeholder: "Enter your full name here*",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Email Address*",
      placeholder: "Enter your email address here*",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: ""
    },
    {
      grpmd: "12",
      label: "Telephone Number*",
      placeholder: "Your phone number*",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Enquiry Nature*",
      placeholder: "Please choose",
      name: "enquiry_nature",
      element: "select",
      required: true,
      type: "select",
      values: ['Buying', 'Selling', 'Letting', 'Mortgages', 'Conveyancing', 'Home For Life Plan', 'Land & New Homes', 'Home Insurance', 'Other'],
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Your Enquiry",
      placeholder: "Write your message here...",
      name: "message",
      element: "textarea",
      class: "mb-0 msg ",
      rows: "3",
      required: false,
      labelClass: "content_b-18"
    },
    {
      step: "col-md-12",
      name: "terms",
      placeholder: 'I agree to the <a href="/terms-and-conditions" class="form-text-link" target="_blank"><u>Terms & Conditions</u></a> and <a href="/privacy-policy" class="form-text-link" target="_blank"> <u>Privacy Policy</u> </a>. <br>',
      class: "mb-4 over-60-checkbox col-md-12 terms-check",
      labelClass: "content_b-18",
      element: "checkbox",
      required: true
    },
    {
      name: "Submit your enquiry",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 text-center",
      class: "btn-secondary",
      formclass: ""
    },
    {
      step: "col-md-12",
      text: 'Prefer to talk? Call us on <a href="tel:0800854499" class="phone-link"><u>0800 854 499</u></a>',
      element: "html",
      class: "apply"
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces 
    event.target.value = event.target.value.trimStart()
  }

  const scrollToTargetAdjusted = () => {
    var element = myRef.current;
    var headerOffset = 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  useEffect(() => {
    getIpAddress((ip) => {
      setIpAddress(ip)
    })
    if (token !== '') {

      const processFromData = async () => {
        formvalues['g-recaptcha-response'] = token;
        const url = typeof window !== 'undefined' ? window.location.href : ''

        var gtm_client_id = '';
        var user_agent = '';

        if (typeof window !== 'undefined') {
          user_agent = window.navigator.userAgent
          if (typeof window.ga !== 'undefined') {
            gtm_client_id = window.ga.getAll()[0].get('clientId');

          }
        }

        let formData = new FormData();
        formvalues['ip_address'] = ipAddress;
        formvalues['name'] = formvalues.name;
        formvalues['terms'] = formvalues.terms == "yes" ? true : false;
        formvalues['enquiry_nature'] = formvalues.enquiry_nature == "Selling" ? "Sales" : formvalues.enquiry_nature;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;
        formvalues['form_page'] = url;
        formvalues['referrer'] = url; 
        formvalues['google_analytics_client_id'] = gtm_client_id;
        formvalues['user_agent'] = user_agent;
        formvalues['brand_id'] = process.env.GATSBY_STRAPI_BRAND_ID;
        formvalues['extra'] = JSON.stringify(formvalues);

        formData.append('data', JSON.stringify(formvalues));


        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          // lets send mail

          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/functions`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })

        });

        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': 'form-' + fields[0].event_tracking + ' - ' + formvalues.enquiry_nature,
          'formLabel': 'form-' + fields[0].event_tracking + ' - ' + formvalues.enquiry_nature,
        });

        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)
        //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        scrollToTargetAdjusted()
      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
    setShowerror(false);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;


    if (form.checkValidity() === false) {
      if(document.querySelector('#terms').checked) {
        $(".terms-check .check-mark").removeClass("error");
      }
      else {
        $(".terms-check .check-mark").addClass("error");
      }
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      scrollToTargetAdjusted()
    }
    else {
      event.preventDefault();
      setShowerror(false);
      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;


      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();
      // setToken("aaa")
      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };
  const search = typeof window !== 'undefined' ? window.location.search : '';
  var selected = "";
  if (search == "?mortgage") {
    selected = "Mortgages"
  }
  var cookieData = getAllCookie();
  return (
    <div class="contact-form-apply">
      <div ref={myRef} />

      {showerror && <div className="alert alert-danger alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      {showthankyou && <div className="alert alert-success">
        <p>{fields[0].success_text}</p>
      </div>}


      <Form className="form contact-landing-form customer-complaint-form" id="contact-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id ? props.to_email_id : ''} />
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form_type_id" value="11" />
        <input type="hidden" name="utm_source" value={cookieData.utm_source} />
        <input type="hidden" name="utm_campaign" value={cookieData.utm_campaign} />
        <input type="hidden" name="utm_term" value={cookieData.utm_term} />
        <input type="hidden" name="utm_content" value={cookieData.utm_content} />
        <input type="hidden" name="utm_medium" value={cookieData.utm_medium} /> 
        <div className="row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  // handlechange={handleAttachment}
                  required={field.required}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  id={field.id}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  alias={props.alias}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  step={field.step}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <Form.Group>
                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    step={field.step}
                    formclass={field.formclass}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                  />
                </Form.Group>
              );
            }
          })
          }
        </div>
      </Form>
    </div>
  );
}


const ContactFormPage = (props) => (
  <ContactForm alias={props.alias} classone={props.classone} to_email_id={props.to_email_id} title={props.title} />
)

export default ContactFormPage