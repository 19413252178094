import React from 'react';
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Banner from '../components/Home/Banner/HomeBanner';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import LandingBanner from '../components/Home/Banner/LandingBanner';
import About from '../components/Home/About/About';
import StaticContent from '../components/StaticContent/StaticContent';
import StaticContentSidebar from "../components/StaticContent/StaticContentSidebar"
import BookValuation from '../components/Home/BookValuation/BookValuation';
import FormBlock from '../components/Home/FormBlock/FormBlock';
import ServiceContentOne from '../components/ServiceContentBlock/ServiceContentOne';
import ServiceContentTwo from '../components/ServiceContentBlock/ServiceContentTwo';
import CountBlock from '../components/Home/CountBlock/CountBlock';
import GlobalCount from '../components/Home/CountBlock/GlobalCount';
import StaffStory from "../components/StaffStory"
import FAQSection from "../components/FAQ/FAQSection"
import GetKnow from '../components/Home/GetKnow/GetKnow';
import OurNetwork from '../components/Home/OurNetwork/OurNetwork';
import OurNetworkIndividual from '../components/Home/OurNetwork/OurNetworkIndividual';
import CaseStudySection from "../components/CaseStudies/LatestCaseStudies"
import Brands from "../components/AreaGuideDetails/OurBrands/OurBrands"
import WhyUse from '../components/WhyUse/WhyUse';
import AccordionModule from "../components/Accordion/Accordion"
import History from "../components/History/History"
import PeopleStories from "../components/People/PeopleStories"
import ContactLanding from "../components/ContactLanding/ContactLanding"
import CaseStudiesLanding from "../components/CaseStudies/Landing"
import Accreditations from "../components/Accreditations/Accreditations"
import NewsLanding from '../components/News/NewsLanding';
import Benefits from '../components/WhyUse/Benefits';
import MortgageLanding from "../components/calculators/mortgage-landing"
import LocRatingMap from '../components/map/loc-rating-map-area-guides';
import Disclaimer from "../components/Disclaimer/Disclaimer"

const Modules = (props) => {
    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat'] = 51.1492505;
    mapItem['lng'] = 0.8708569;
    mapItem['id'] = 1;
    mapItems.push(mapItem);
    return (
        <React.Fragment>
            <SEO title={props.page.Meta_Title} description={props.page.Meta_Description} image={props.page.Banner_Image ? props.page.Banner_Image.url : ''} />
            <div className={props.page.Layout === 'Homepage' ? "home-header" : props.page.Layout === 'Default' ? 'wrapper area-guide-wrapper' : props.page.Layout === 'Staticpage' || props.page.Layout === 'Background_Image_Layout' ? 'wrapper static-page' : ''}>
                <Header />

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL === 'home' &&
                    <Banner {...props.page} />
                }

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL !== 'home' &&
                    <Banner {...props.page} />
                }
                {props.page.Layout === 'Default' && props.page.Banner_Image &&
                    <>
                        <Breadcrumbs {...props.page} />
                        <LandingBanner {...props.page} />
                    </>
                }

                {props.page.Layout === 'Background_Image_Layout' && props.page.Banner_Image &&
                    <Breadcrumbs {...props.page} />
                }
                {props.page.Layout === 'Default' && props.page.Banner_Image === null &&
                    <>
                        <Breadcrumbs {...props.page} />
                    </>
                }
            </div>

            {
                props.modules && props.modules.map((module, i) => {
                    return (
                        <>
                            {module.Intro_Content &&
                                <About {...module} />
                            }

                            {module.Add_Service_Tile &&
                                <GetKnow {...module} />
                            }

                            {module.Add_Static_Content &&
                                <>
                                    {module.Style == 'With_Sidebar' ?
                                        <StaticContentSidebar {...module} {...props.page} count={i} /> : <StaticContent {...module} style={module.Style} count={i} />
                                    }
                                </>
                            }

                            {module.Select_Module && module.Select_Module.Help_CTA_1_Link == null &&
                                <FormBlock {...module.Select_Module} count={i} />
                            }

                            {module.Select_Module && module.Select_Module.Help_CTA_1_Link &&
                                <BookValuation {...module.Select_Module} />
                            }
                            
                            {module.Add_Accreditation &&
                                <Accreditations {...module} />
                            }

                            {/**Service content block*/}
                            {module.Add_New_Block &&
                                <>
                                    {module.Add_New_Block.length === 2 ?
                                        <ServiceContentTwo {...module} num="0" class="only-two" /> :
                                        <>
                                            {module.Add_New_Block && module.Add_New_Block.slice(0, 1).map((service, i) => {
                                                return (
                                                    <ServiceContentOne {...service} />
                                                )
                                            })}
                                            {module.Add_New_Block.length > 1 &&
                                                <ServiceContentTwo {...module} num="1" />
                                            }
                                        </>
                                    }
                                </>
                            }

                            {/**Statistics block*/}
                            {module.Add_Stats &&
                                <CountBlock {...module} />
                            }
                            {module.Stats_Title && <GlobalCount {...module} />}

                            {/**Staff story block*/}
                            {module.Add_Staff_Stories &&
                                <StaffStory {...module} />
                            }

                            {/**FAQ block*/}
                            {module.Select_FAQs &&
                                <FAQSection {...module} />
                            }

                            {/**Our Network */}
                            {module.Choose_Module === "Our_Network" &&
                                <OurNetwork />
                            }
                            {module.type === "GLSTRAPI_ComponentComponentsAddOurNetwork" &&
                                <OurNetworkIndividual {...module} />
                            }
                            {module.type === "GLSTRAPI_ComponentModulesPeopleStories" &&
                                <PeopleStories  {...module} />
                            }
                            {/**Locrating Map */}
                            {module.Choose_Module === "Locrating_Map" &&
                                <div className="locrating-map">
                                    <LocRatingMap data={mapItems} />
                                </div>
                            } 
                            {/**Why Arun Estates */}
                            {module.Choose_Module === "Why_Arun_Estates" &&
                                <WhyUse />
                            }

                            {/**Case Studies */}
                            {module.Select_Case_Studies_to_Show &&
                                <CaseStudySection {...module} />
                            }

                            {module.Add_Brand &&
                                <Brands {...module} />
                            }

                            {module.Add_Accordion &&
                                <AccordionModule {...module} />
                            }

                            {module.Choose_Module === "History_Slider" &&
                                <History />
                            }


                            

                            {module.Add_Contact_Detail &&
                                <ContactLanding {...module} page={props.page} />
                            }

                            {module.Select_Collection === "Case_Studies" &&
                                <CaseStudiesLanding />
                            }
                            {module.Select_Collection === "News" &&
                                <NewsLanding />
                            }
                            {module.Add_Benefit &&
                                <Benefits  {...module} />
                            }
                            {module.Choose_Module === "Mortgage_Calculators" &&
                                <MortgageLanding />
                            }
                            {module.Add_Disclaimer_Content &&
                                <Disclaimer {...module}/>
                            }

                        </>
                    )
                })
            }
            <Footer />
        </React.Fragment>
    )
}
export default Modules;
