import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser"
import Slider from "react-slick";

import "../Home/GetKnow/GetKnow.scss";
import "../DevelopmentDetails/OurPeople/OurPeople.scss"

const OurPeople = (props ) => {

    
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        history {
            Title
            Add_New {
              Content
              Image {
                url
                alternativeText
              }
              Year
            }
        }
    }
  }
`);

const history = data.glstrapi.history;


  const settings1 = {
    dots: true,
    speed: 800,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    infinite: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          
          slidesToShow: 4,
          dots: false
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
         
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          speed: 500,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          speed: 500,
        }
      }
    ]
  };
  
  return ( 
        <React.Fragment>
          <div className="other-areas-block our-people history-main-slider">
            <section className={`scroll-text-bg ${history.Title.replace(/ /g, "-").toLowerCase()}`}>
              <Container>
                  <Row>
                    <Col md="12">
                        {/* <div className="block-heading">
                        {history.Title &&
                        <h2>{history.Title}</h2>
                        }
                        </div> */}
                      <div className="team-slider">
                        <Slider className="banner-imgslider d-block" {...settings1}>
                        {history.Add_New.map((item, index) => (
                            <div className="team-item">
                            <Link className="img-zoom">
                            <picture>
                                <img src={item.Image.url} alt="" />
                            </picture>
                            </Link>
                            <div className="team-block">
                            <span className="person-name">{item.Year}</span>
                            <span className="person-profile">{parse(item.Content)}</span>
                            </div>
                        </div>
                        ))}
                        </Slider>
                    </div>

                    </Col>
                  </Row>
              </Container>
            </section>
           </div>
        </React.Fragment>
    )
}
export default OurPeople