import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import parse from 'html-react-parser';

import { NewsDate } from "../common/site/functions"
import { CASE_STUDIES_PAGE_URL } from "../common/site/constants"
import Enquiry from "../Branches/PeopleEnquiry";

import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import _ from "lodash"
function LatestCaseStudies(props) {
  const sliderRef = useRef();
  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          speed: 500,
        },
      },
    ],
  };

  const next = () => {
    sliderRef.current.slickNext();
  }
  const previous = () => {
    sliderRef.current.slickPrev();
  }
 


  var sortOrder = ['Land & New Homes Director', 'Regional New Homes & Corporate Business Department Director', 'Area Manager', 'Head of Land', 'Shared Ownership Manager', 'Corporate Sales Manager', 'Senior Sales Progressor','New Homes Business Development Manager', 'New Homes Marketing Manager','Regional Director', 'Area Manager',  'Shared Ownership Manager', 'Senior Sales Progressor'];

  // var sortPeoples = props.Peoples.sort((a, b) => sortOrder.indexOf(a.Designation) - sortOrder.indexOf(b.Designation));
  var sortPeoples = []; 
  if(props.Add_People){
    sortPeoples = props.Add_People.map(a => a.People); 
  }

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="guides-section-list latest-case-study-list people-stories"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}

        >
          <Container>
            <Row className="head">

              {props.Title &&
                <Col xs={6} lg={12} sm={12}  className="team-main-nav">
                  <motion.span variants={contentItem} className="sub-title">
                    {props.Title}
                  </motion.span>
                  <div className="arrows d-lg-none d-inline">
                    <button className="button" onClick={previous}>
                      <i className="icon-arrowleft"></i>
                    </button>
                    <button className="button" onClick={next}>
                      <i className="icon-arrowright"></i>
                    </button>
                  </div>
                </Col>
              }
              <Col xs={6} className="mobile-link">
                <Link to={CASE_STUDIES_PAGE_URL.alias} class="link-text desktop"><span>More Case Studies</span><i class="icon-arrow"></i></Link>
              </Col>

              <Col lg={8}>
                <motion.div variants={fadeInFromTop} className="block-heading">
                  {parse(props.Content)}
                </motion.div>
              </Col>
              <Col lg={4} className="d-lg-block d-none">
                <div className="arrows">
                  <button className="button" onClick={previous}>
                    <i className="icon-arrowleft"></i>
                  </button>
                  <button className="button" onClick={next}>
                    <i className="icon-arrowright"></i>
                  </button>
                </div>
              </Col>
            </Row>

            <Slider {...settings} className="guide-tile-slide" ref={sliderRef}>
              {sortPeoples && sortPeoples.map((item, index) => {
                return (

                  <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="guide">
                    <div className="img-zoom">
                      <img src={item.Image.url} alt={item.Image.alternativeText} />
                    </div>
                    <p className="title">{item.Name}</p>
                    <p className="content">{item.Designation}</p>
                    <p className="email"><i className="icon-envelope"></i> <Enquiry name={item.Name}> Email {item.Name} </Enquiry> </p>
                  </motion.div>

                )
              })}
            </Slider>

          </Container>
        </motion.section>
      )}
    </InView>
  )
}

export default LatestCaseStudies;
