import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"


const SelectField = ({ name, required, placeholder, values, handlechange, fieldClass, label, grpmd, componentprops, step, type, id, alias }) => {

    const [formtype, setFormType] = useState('');
    const [landselect, setLandSelect] = useState(false);

    useEffect(async () => {
        const pageurl = typeof window !== 'undefined' ? window.location.href : ''
        var pageval = new URL(pageurl)
        var typeval = pageval.searchParams.get("type")
        if (typeval) {
            setFormType(typeval)
            if (typeval == "land-and-new-homes") {
                setLandSelect(true)
            }
        }
        if (alias == "contact-form-land-and-new-homes") {
            setLandSelect(true)
        }
    }, [alias]);

    return (
        <Form.Group as={Col} md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
            {label
                ? <Form.Label className="form-label">{label}{required ? '' : ''}</Form.Label>
                : ''

            }
            {formtype}
            <Form.Control
                controlId={id}
                className={fieldClass ? fieldClass + ' form-select' : ' form-select'}
                required={required}
                name={name}
                onChange={handlechange}
                as="select"
            >
                <option value="" key="">{placeholder}</option>
                <option value="Sales">Buying</option>
                <option value="Selling">Selling</option>
                <option value="Lettings" selected={alias == "contact-form-landlords"}>Lettings</option>
                <option value="Mortgages" selected={alias == "contact-form-mortgages"}>Mortgages</option>
                <option value="Conveyancing" selected={alias == "contact-form-conveyancing"}>Conveyancing</option>
                <option value="Over 60s Budget Booster" selected={alias == "contact-form-homeforlifeplan"}>Home For Life Plan</option>
                <option value="Land &amp; New Homes" selected={landselect}>Land &amp; New Homes</option>
                <option value="Home Insurance">Home Insurance</option>
                <option value="Careers">Careers</option>
                <option value="Other">Other</option>
            </Form.Control>
        </Form.Group >

    )
}

export default SelectField;