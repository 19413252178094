import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card, Accordion, AccordionContext, useAccordionButton } from "react-bootstrap";
import parse from 'html-react-parser';

import "./FAQSection.scss"

const FAQSection = (props) => {
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-up" : "icon-down"
                    }
                ></i>
            </button>
        )
    }
    return (
        <React.Fragment>
            <section className="faq-details faq-section-list components--FAQ--FAQSection">
                <Container>
                    <Row className="head">
                        <Col xs={6} lg={12}>
                            <span class="sub-title">Frequently Asked Questions</span>
                        </Col>
                        <Col xs={6} className="mobile-link">
                            <Link class="link-text" to="/faq"><span>More FAQs</span><i class="icon-arrow"></i></Link>
                        </Col>

                        <Col lg={8}>
                            <h2>{props.FAQ_Title}</h2>
                        </Col>
                        <Col lg={4}>
                            <Link class="link-text desktop" to="/faq"><span>More FAQs</span><i class="icon-arrow"></i></Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Accordion >
                                {props.Select_FAQs && props.Select_FAQs.map((item, i) => (
                                    <Card>
                                        <div className="accordion-header card-header">
                                            <ContextAwareToggle eventKey={i + 1} className="accordion-header">
                                                <h4>{item.Question}</h4>
                                            </ContextAwareToggle>
                                        </div>
                                        <Accordion.Collapse eventKey={i + 1}>
                                            <div className="accordion-card accordion-card-first card-body">
                                                <div className="accordion-list">
                                                    {parse(item.Answer)}
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>

            </section>
        </React.Fragment>
    )
}

export default FAQSection;
