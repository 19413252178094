import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import ModalTiny from "react-bootstrap/Modal"
import _ from "lodash"
import Select, { components } from 'react-select';
import { useWindowSize } from '../../hooks/winodw-size'

import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby";
import "../AreaGuideDetails/News/News.scss"
import MainNews from "../AreaGuideDetails/News/News"
import { NewsDate } from "../common/site/functions"
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import PlayVideo from '../Play/CustomVideo';
import FormBlock from '../Home/FormBlock/FormBlock';
import $ from "jquery"

import "../Branches/Branches.scss";


const postsPerPage = 16;
let arrayForHoldingPosts = [];


const CaseStudiesLanding = () => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        caseStudies(publicationState: LIVE, sort: "Date:desc") {
            Date
            Title
            Short_Description
            Video_URL
            URL
            id
            Tile_Image {
              url
              alternativeText
            }
            Address
            Add_Content {
              ... on GLSTRAPI_ComponentModulesContent {
                Add_Content
              }
            }
            Category {
              Name
              URL
            }
            imagetransforms
        }

        caseStudiesCategories(sort: "Sort:asc") {
          Name
          Sort
          URL
          case_studies {
            Title
            URL
          }
        }

      globalModule {
        Case_Studies_Landing_Page {
          CTA_1_Label
          CTA_2_Label
          Content
          Name
          Show_Chart
          Select_Form {
            Select
          }
          CTA_1_Link {
            id
          }
          CTA_2_Link {
            id
          }
        }
      }

    }
  }
`);

  const allnews = data.glstrapi.caseStudies;
  const allcats = data.glstrapi.caseStudiesCategories;
  const global = data.glstrapi.globalModule.Case_Studies_Landing_Page;
  const [category, setCategory] = React.useState("all")
  const [filteredTeam, setFilteredTeam] = useState([]);
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(16);
  const [filterupdate, setFilterUpdate] = React.useState(false)
  const [search_text, setSearchText] = useState('');
  const [loadtext, setLoadText] = useState('');
  const [catupdate, setCatUpdate] = React.useState(1)
  const [selectedArea, setSelectedArea] = useState([{ value: 'all', label: 'All Case Studies' }]);
  const [catList, setCatList] = useState([]);
  const [windowWidth] = useWindowSize();
  const [isPlay, setPlay] = useState(false);
  const [show, setShow] = useState(false);
  const showFilter = () => {
    setShow(!show);
  };


  const BranchFilter = (url) => {
    setCatUpdate(2);
    setCategory(url)
    setFilterUpdate(true)
    setNext(16)
    var search_val = document.getElementById('search').value
    setSearchText(search_val);
  }

  const handlechange = (event) => {
    // remove initial empty spaces
    setCatUpdate(2)
    var search_val = document.getElementById('search').value
    setSearchText(search_val);
    setFilterUpdate(true)
  }


  const handlechangeOne = (event) => {
    // remove initial empty spaces
    if (event.key == "Enter") {
      setCatUpdate(2)
      // var search_val = event.target.value.trimStart();
      var search_val = document.getElementById('search').value
      setSearchText(search_val);
      setFilterUpdate(true)
    }
  }

  const filteredList = () => {
    setFilteredTeam([])
    var filteredTeamList = []
    if (catupdate == 2) {
      setCatUpdate(3)
    }
    if (category == "all" && search_text == '') {
      filteredTeamList = allnews
    }
    else {
      if (search_text) {
        for (var i = 0; i < allnews.length; i++) {
          var full_name = allnews[i].Title.toLowerCase()
          if (full_name.includes(search_text.toLowerCase())) {
            filteredTeamList.push(allnews[i])
          }
        }
      }
      else {
        filteredTeamList = allnews.filter(branch => {
          let main_category = branch.Category.filter(cat => _.includes(cat, category));
          return main_category.length > 0
        });
      }
    }
    if (filteredTeamList.length == 0) {
      setLoadText("Sorry, No results found")
    }
    setFilteredTeam(filteredTeamList)
    setFilterUpdate(false)
  }

  const loopWithSlice = (start, end) => {
    const slicedPosts = filteredTeam.slice(start, end);
    arrayForHoldingPosts = [...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const loopWithSliceMore = (start, end) => {
    const slicedPosts = filteredTeam.slice(start, end);

    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSliceMore(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  useEffect(() => {
    if ((catupdate == 1 || catupdate == 2) && (filteredTeam.length == 0 || filterupdate)) {
      filteredList()
    }
    loopWithSlice(0, postsPerPage);
    if (_.isEmpty(catList)) {
      let cats = [{ value: 'all', label: 'All Case Studies' }];
      allcats.map((area) => {
        if (area.case_studies.length > 0) {
          cats.push({ value: area.URL, label: area.Name })
        }

      })
      setCatList(cats)
    }


    // iphone zoom issue when type a text in input field
    $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0">');
    // iphone zoom issue when type a textin input field

  }, [filteredTeam, category, search_text]);
  const customStyles = {
    control: styles => ({
      ...styles, outline: 0, border: 'none', boxShadow: 'none', borderRadius: '8px', backgroundColor: '#CCE7EE',
      color: 'red', fontWeight: 'bold', height: "48px", width: "140px"
    }),
    singleValue: base => ({
      ...base,
      color: '#005670'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#005670", // Custom colour
      fontSize: "24px"
    })

  }
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? '-' : '+'}
      </components.DropdownIndicator>
    );
  };
  return (
    <React.Fragment>
      <section className="similar-property news-main-landing-page case-studies-landing">
        <Container>
          <Row>

            <div className="news-wrap">
              <Container>
                <Row>
                  <Col md="12">
                    <div className="tab-wrap">
                      <div className="d-none d-lg-flex">
                        {/* <button type="button" className={`all-btn ${category === 'all' && "active"}`} onClick={() => { BranchFilter('all'); showFilter(); }}>All Case Studies <span>{show ? "-" : "+"}</span></button> */}
                        <div className={show ? "tabing-list d-block" : "tabing-list "} >
                          <ul className="area-guide-listing-desktop">
                            <li>
                              <a href="javascript:void(0);" className={`all-btn ${category === 'all' && "active"}`} onClick={() => { BranchFilter('all'); showFilter(); }}>All Case Studies</a>
                            </li>
                            {allcats.map((area, index) => (
                              <React.Fragment>
                                {area.case_studies.length > 0 &&
                                  <li>
                                    <a href="javascript:void(0);" className={`${category === area.URL && "active"}`} onClick={() => { BranchFilter(area.URL); }}>{area.Name}</a>
                                  </li>
                                }
                              </React.Fragment>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="d-block d-lg-none">
                        <Select
                          name={`area`}
                          value={selectedArea}
                          options={catList}
                          className={``}
                          onChange={(selected) => {
                            setSelectedArea(selected)
                            BranchFilter(selected.value);
                          }}
                          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                          classNamePrefix="select"
                          styles={customStyles}
                        />
                      </div>
                      <div className="form news-filter">
                        {/* <i className="icon-search"></i> */}
                        {windowWidth < 768 && <Form.Control autoFocus id="search" onKeyDown={handlechangeOne} placeholder="Search..." />}
                        {windowWidth >= 768 && <Form.Control autoFocus id="search" onKeyDown={handlechangeOne} placeholder="Search news" />}
                        <button type="submit" className="search-btn" onClick={handlechange}> <i className="icon-search"></i></button>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {postsToShow.length == 0 &&
              <p className="no-results-found">{loadtext}</p>
            }

            {postsToShow.slice(0, 1).map((item, index) => (
              <MainNews {...item} Content={item.Add_Content[0].Add_Content} imagename='case-studies.Tile_Image.tile_2' />
            ))}

            {postsToShow.length > 1 &&
              <Row className="news-list">
                {postsToShow.slice(1, 4).map((node, index) => {
                  const image_url = node.Tile_Image?.url;

                  let processedImages = JSON.stringify({});
                  if (node.imagetransforms?.Tile_Image_Transforms) {
                    processedImages = node.imagetransforms.Tile_Image_Transforms;
                  }
                  return (
                    <React.Fragment>
                      <Col lg={4}>
                        <div className="news-item">
                          <div className="image img-zoom">
                            <Link to={node.URL}>
                              <ImageTransform
                                imagesources={image_url}
                                renderer="srcSet"
                                imagename="case-studies.Tile_Image.tile_1"
                                attr={{ alt: node.Tile_Image.alternativeText, class: '' }}
                                imagetransformresult={processedImages}
                                id={node.id}
                              />
                              {/* < img src={node.Tile_Image.url} alt={node.Tile_Image.alternativeText} /> */}
                            </Link>
                            {node.Video_URL &&
                              <PlayVideo url={node.Video_URL} />
                            }
                          </div>
                          <Link to={node.URL}>
                            <div className="content">
                              <p className="title"><span>{node.Title}</span></p>
                              <p className="date">{node.Address}</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      {/* {index == 2 &&
                        <Col lg={12}>
                          <FormBlock {...global} />
                        </Col>
                      } */}
                    </React.Fragment>
                  )}
                )}
              </Row>
            }
          </Row>
        </Container>

        <FormBlock {...global} />

        <Container>
          <Row>
            {postsToShow.length > 2 &&
              <Row className="news-list">
                {postsToShow.slice(4, 1000).map((node, index) => {
                  const image_url = node.Tile_Image?.url;

                  let processedImages = JSON.stringify({});
                  if (node.imagetransforms?.Tile_Image_Transforms) {
                    processedImages = node.imagetransforms.Tile_Image_Transforms;
                  }
                  return (
                    <React.Fragment>
                      <Col lg={4}>
                        <div className="news-item">
                          <div className="image img-zoom">
                            <Link to={node.URL}>
                              <ImageTransform
                                imagesources={image_url}
                                renderer="srcSet"
                                imagename="case-studies.Tile_Image.tile_1"
                                attr={{ alt: node.Tile_Image.alternativeText, class: '' }}
                                imagetransformresult={processedImages}
                                id={node.id}
                              />
                              {/* < img src={node.Tile_Image.url} alt={node.Tile_Image.alternativeText} /> */}
                            </Link>
                            {node.Video_URL &&
                              <PlayVideo url={node.Video_URL} />
                            }
                          </div>
                          <Link to={node.URL}>
                            <div className="content">
                              <p className="title"><span>{node.Title}</span></p>
                              <p className="date">{node.Address}</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </React.Fragment>
                  )}
                )}
              </Row>
            }



            {arrayForHoldingPosts.length !== filteredTeam.length ?
              <div className="area-guide-load-more people-landing-load-more"><button className="btn btn-pagination" onClick={() => handleShowMorePosts()}>Load more</button></div>
              : ''
            }
          </Row>
        </Container>
      </section>

    </React.Fragment >
  )
}
export default CaseStudiesLanding
