import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col, Form  } from "react-bootstrap"
import Calculator from "./how-much-borrow"
import MortCalculator from "./mortgagecalc/index"

const MortgageLanding = (props) =>{
    return (
        <div className="mortgage-calculators-section">
            <div className="how-much-borrow">
                <h3>How much can I borrow?</h3>
                <Calculator />
                <p>The salary calculator is based on Gross salary x4.5 and is an indicator of what some lenders my lend. We haven’t taken into account any current commitments they may reduce this amount. This figure should only be used as a guide, please contact us for personal and accurate advice.</p><p>Your home may be repossessed if you do not keep up repayments on a mortgage or any debt secured upon it.</p>
            </div>
            <div className="mortgage-cost">
                <h3>What will my mortgage cost?</h3>
                <MortCalculator />
                <p>This calculator is based on a capital repayment mortgage. This figure should only be used as a guide, please contact us for personal and accurate advice.</p>
                <p>Your home may be repossessed if you do not keep up repayments on a mortgage or any debt secured upon it.</p>
            </div>
        </div>
    )
}

export default MortgageLanding;
