import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import parse from "html-react-parser"
import "./Contact.scss"

import Contact from "../forms/contact-form"

const ContactLanding = (props) => {
  const [alias, setAlias] = useState('');

  useEffect(async () => {
    if (localStorage.getItem('page-alias')) {
      setAlias(localStorage.getItem('page-alias'))
    }
  }, []);
    return (
        <React.Fragment>
          <section className="valuation-page contact-form-page" style={{background:"url(" + props.page.Banner_Image.url + ")"}}>
            <div className="page">
              <Container>
                <Row>
                  <Col md='12' className="head">
                    {parse(props.page.Banner_Content)}
                  </Col>
                </Row>
                <Row>
                  <Col md='12' lg={7}>
                    <div className="valuation-form contact-main-form">
                        <div className="main-calc">
                            <div className="form-wrapper">
                                <Contact alias={alias}/>
                            </div>
                        </div>
                    </div>
              </Col>
              <Col lg={1} className="">
              </Col>
              <Col lg={4} className="d-lg-block">
                <div className="address">
                    <h4>Contact Details</h4>
                    {props.Add_Contact_Detail.map((module, i) => {
                        return (
                            <div className="content">
                                {parse(module.Details)}
                            </div>
                        )
                    })}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment >
    )
}

export default ContactLanding